<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="65px"
        class="ms-content"
      >
        <el-form-item prop="username" label="用户名">
          <el-input v-model="param.username" placeholder="username"> </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            type="password"
            placeholder="password"
            v-model="param.password"
            @keyup.enter.native="submitForm()"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="login-btn">
            <el-button type="primary" @click="submitForm()" :loading="isLoading"
              >登录</el-button
            >
          </div>
        </el-form-item>
        <p class="login-tips">Tips : 用户名和密码都是admin。</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUUID } from "@/util/http";
import api from "@/api/api";
import role from "@/util/role";
export default {
  name: "login",
  data: function () {
    return {
      param: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isLoading: false,
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          let data = await api.loginApi.loginIn({
            username: this.param.username,
            password: this.param.password,
            uuid: getUUID(),
          });
          if (data.code == 0) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            this.$cookies.set("token", data.token, "1d");
            this.$cookies.set("username", this.param.username, "1d");
            this.$cookies.set("curRole", data.curRole, "1d");
            // this.$router.replace("/");
            this.handleInitPage();
            this.isLoading = false;
          }
          if (data.code != 0) {
            this.$message({
              message: data.msg,
              type: "warning",
            });
            this.isLoading = false;
            return;
          }
        } else {
          this.$message.error("请输入账号和密码");
          this.isLoading = false;
          return false;
        }
      });
    },
    //初始跳转页面
    handleInitPage() {
      const curRole = this.$cookies.get("curRole");
      this.recInitPage(role[curRole]);
    },
    //递归展示首页
    recInitPage(router) {
      if (router[0].subs) {
        this.recInitPage(router[0].subs);
      } else {
        this.$router.replace(router[0].index);
      }
    },
  },
};
</script>

<style scoped lang="less">
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/login-bg.jpg");
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
  /deep/.el-input-group__prepend {
    width: 30px;
    flex: 0 0 auto;
    overflow: hidden;
  }
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>
